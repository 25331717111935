import React, { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../Components/AdminLayout/AdminLayout";
import "./EventDetails.scss";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import CustomToolTip from "../../Components/CustomToolTip/CustomToolTip";
import {
  DELETE_EVENT,
  ENABLE_OR_DISABLE_EVENT,
  GET_ALL_EVENT,
} from "../../utils/apiPath";
import { deleteApi, postApi } from "../../utils/apiService";
import { errorToast, successToast } from "../../services/ToastHelper";
import { useNavigate } from "react-router-dom";
import AdminConfirm from "../../Components/AdminConfirm/AdminConfirm";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch, Pagination } from "antd";
import Loader from "../../Components/Loader/Loader";
import FormInputs from "../../Components/FormInputs/FormInputs";
import { debounce } from "lodash";

const EventDetails = () => {
  const [eventDetails, setEventDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [switchStates, setSwitchStates] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEvents] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [pageSize] = useState(5);
  const navigate = useNavigate();

  const fetchAllEventDetails = async (pageIndex = 0, searchStr = "") => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const payload = {
        pageIndex,
        pageSize,
        searchString: searchStr,
      };
      const { statusCode, error, data, totalRecords } = await postApi(
        GET_ALL_EVENT,
        // { params: { isEnabled: null } },
        payload
      );

      if (statusCode === 200) {
        setEventDetails(data);
        setTotalEvents(totalRecords);
        const initialSwitchStates = {};
        data.forEach((event) => {
          initialSwitchStates[event.eventId] = event.isEnable;
        });
        setSwitchStates(initialSwitchStates);
      } else {
        errorToast(error);
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllEventDetails(currentPage - 1, searchString);
  }, [currentPage, searchString]);

  // const formatDateTime = (dateStr) => {
  //   const date = new Date(dateStr);
  //   return `${date.toLocaleDateString()} @ ${date.toLocaleTimeString()}`;
  // };

  const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const formatTime = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handleEditEvent = (eventId) => {
    navigate(`/add-events`, { state: { eventId: eventId } });
  };

  const handleDeleteEvent = (eventId) => {
    setEventToDelete(eventId);
    setConfirmVisible(true);
  };

  const confirmDeleteEvent = async () => {
    const { statusCode, error, message } = await deleteApi(DELETE_EVENT, {
      params: { eventId: eventToDelete },
    });
    if (statusCode === 200) {
      successToast(message);
      fetchAllEventDetails();
      setConfirmVisible(false);
    } else {
      errorToast(error);
      setConfirmVisible(false);
    }
  };

  const handleSwitchChange = async (eventId, checked) => {
    try {
      const { statusCode, error, message } = await postApi(
        ENABLE_OR_DISABLE_EVENT,
        {},
        {
          params: { eventId },
        }
      );
      if (statusCode === 200) {
        successToast(message);
        setSwitchStates((prevStates) => ({
          ...prevStates,
          [eventId]: checked,
        }));
        fetchAllEventDetails();
      } else {
        errorToast(error);
      }
    } catch (error) {
      console.error("Error updating event status:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLinkPage = (eventData) => {
    navigate(`/event-link`, { state: { eventData: eventData } });
  };

  const debouncedFetchEventDetails = useCallback(
    debounce((searchStr) => {
      setSearchString(searchStr);
      setCurrentPage(1);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    debouncedFetchEventDetails(e.target.value);
  };

  return (
    <AdminLayout>
      {isLoading && <Loader />}
      <div className="event">
        <div className="event_cont">
          <div className="event_cont_sec">
            <div className="event_cont_sec_heading">
              <h2>Events Details</h2>
            </div>
            <div className="event_cont_sec_search">
              <FormInputs
                icon="icon3"
                type="text"
                placeholder="Search Events"
                onChange={handleSearchChange}
              />
            </div>
            {eventDetails.map((item) => (
              <div key={item.eventId} className="event_cont_sec_body">
                <div className="event_cont_sec_body_left">
                  <div className="event_cont_sec_body_left_date">
                    <div className="event_cont_sec_body_left_date_month">
                      <span>
                        {new Date(item.fromDate).toLocaleString("en-us", {
                          month: "short",
                        })}
                      </span>
                    </div>
                    <div className="event_cont_sec_body_left_date_day">
                      <span>{new Date(item.fromDate).getDate()}</span>
                    </div>
                    <div className="event_cont_sec_body_left_date_year">
                      <span>{new Date(item.fromDate).getFullYear()}</span>
                    </div>
                  </div>
                </div>
                <div className="event_cont_sec_body_right">
                  <div className="event_cont_sec_body_right_details">
                    <div className="event_cont_sec_body_right_details_time">
                      <div className="event_cont_sec_body_right_details_time_left">
                        <span>{`${formatDateTime(item.fromDate)} - ${formatTime(
                          item.toDate
                        )}`}</span>
                      </div>
                      <div className="event_cont_sec_body_right_details_time_right">
                        <CustomToolTip placement="top" title={"Change Status"}>
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={switchStates[item.eventId] || false}
                            onChange={(checked) =>
                              handleSwitchChange(item.eventId, checked)
                            }
                          />
                        </CustomToolTip>
                        <CustomToolTip placement="top" title={"Edit Event"}>
                          <FormOutlined
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "#838383",
                            }}
                            onClick={() => handleEditEvent(item.eventId)}
                          />
                        </CustomToolTip>
                        <CustomToolTip placement="top" title={"Delete Event"}>
                          <DeleteOutlined
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "#838383",
                            }}
                            onClick={() => handleDeleteEvent(item.eventId)}
                          />
                        </CustomToolTip>
                      </div>
                    </div>
                    <div className="event_cont_sec_body_right_details_title">
                      <h2 onClick={() => handleLinkPage(item)}>
                        {item.eventTitle}
                      </h2>
                    </div>
                    <div className="event_cont_sec_body_right_details_content">
                      <div className="event_cont_sec_body_right_details_content_text">
                        <p>{item.shortDescription}</p>
                      </div>
                      {/* <div className="event_cont_sec_body_right_details_content_image">
            {item.imageUrl ? (
              <img src={item.imageUrl} alt="Event Details" />
            ) : null}
          </div> */}
                    </div>
                  </div>
                </div>
                <div className="event_cont_sec_body_image">
                  {item.imageUrl ? (
                    <img src={item.imageUrl} alt="Event Details" />
                  ) : null}
                </div>
              </div>
            ))}
            <div style={{ paddingBottom: "20px" }}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalEvents}
                onChange={handlePageChange}
                showSizeChanger={false}
                align="center"
              />
            </div>
          </div>
        </div>
      </div>
      {confirmVisible && (
        <AdminConfirm
          title={"Are you sure you want to Delete this Event"}
          onCancel={() => setConfirmVisible(false)}
          onConfirm={confirmDeleteEvent}
        />
      )}
    </AdminLayout>
  );
};

export default EventDetails;
